<script setup lang="ts">
import { OuiMobileActivator, OuiTooltipActivator } from 'oui-kit'
import AppFooter from '@/components/app-footer.vue'
import AppHeader from '@/components/app-header.vue'
import { useAppHeadHoltwick } from '@/lib/use-app-head-holtwick'
import { useExternalLinks } from '@/lib/use-external-links'

useAppHeadHoltwick()
useExternalLinks()
</script>

<template>
  <AppHeader title="Briefing" rounded>
    <template #logo>
      <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#272727" d="M0 0h512v512H0z" /><path d="M179.899 367.582c17.271 0 32.662-14.564 32.897-32.887-.235-17.853-15.626-32.418-32.897-32.418-18.21 0-33.131 14.565-32.896 32.418-.235 18.323 14.686 32.887 32.896 32.887zM366 112h-54.984l-77.542 288h54.984L366 112z" fill="#5EA5FF" /></svg>
    </template>
    <router-link class="app-header-menu" :to="`/${$i18n.locale}/help`">
      {{ $t("index.help") }}
    </router-link>
    <!-- <router-link class="app-header-menu" :to="`/${$i18n.locale}/buy`">
      {{ $t("index.buy") }}
    </router-link>
    <a class="app-header-menu" href="/latest">
      {{ $t("index.download") }}
    </a> -->
  </AppHeader>
  <template v-if="$route.meta.pure">
    <router-view />
  </template>
  <template v-else>
    <main class="app-content">
      <Suspense>
        <router-view />
      </Suspense>
    </main>
  </template>
  <AppFooter
    link-holtwick
    support
    imprint
    privacy
    github
    github-url="https://github.com/holtwick/briefing"
    mastodon
  />
  <ClientOnly>
    <OuiTooltipActivator />
    <OuiMobileActivator />
  </ClientOnly>
</template>
