import type { SiteConfig } from '@/_types'

export const siteConfig: SiteConfig = {
  name: 'briefing',
  host: 'about.brie.fi',

  languages: ['en', 'de'],
  i18nLocalesModules: {
    en: () => import('../locales/en.json'),
    de: () => import('../locales/de.json'),
  },

  siteTitle: 'Briefing',
  baseUrl: 'https://about.brie.fi',
  iconUrl: 'https://about.brie.fi/logo.svg',
  // postsPath: 'blog',

  plausibleId: 'about.brie.fi',

  repliesFaq: false,
  repliesProductName: 'Briefing',
  repliesClientId: '97p290opikg4pm3e1kun2v5lvs',

  supportEmail: 'support@holtwick.de',

  newsletterIdList: [
    'bf231d59-f284-4534-8bfa-2ed2066d9443',
  ],
}
