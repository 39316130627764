import App from './app.vue'
import { siteConfig } from './config'
import { routes } from './router'
import { setupMainStandard } from '@/main-standard'

import '@/styles/index.styl'
import '@/styles/theme.styl'

// To be used in Paddle passthrough or other interactive locations
try {
  if (document.referrer) {
    sessionStorage.setItem('ref', document.referrer)
  }
  else if (location.search) {
    const ref = (new URLSearchParams(location.search))?.get('ref')
    if (ref)
      sessionStorage.setItem('ref', ref)
  }
}
catch (err) {}

// https://github.com/antfu/vite-ssg
export const createApp = setupMainStandard({
  appComponent: App,
  routes,
  siteConfig,
  async setup(ctx) {
    const components = await import('./components')
    await components.install(ctx)
  },
})
